import { createWebHistory, createRouter } from 'vue-router';
// import $common from "/src/assets/js/common";


const routes = [
	{
		path: '/hello',
		name: 'Hello',
		component: () => import('@/components/HelloWorld'), // 동적 import
	},
	{
		path: '/',
		redirect: "/main",
		name: 'TheContainer',
		component: () => import('@/components/layout/TheContainer'),
		children: [
			{
				path: '/main',
				name: 'Main',
				component: () => import('@/components/Main'),
			},
			{
				path: '/signin',
				name: 'Signin',
				component: () => import('@/components/SignIn'),
			},
			{
				path: '/customer/join',
				name: 'CustomerJoin',
				component: () => import('@/components/Join'),
			},
			{
				path: '/board/list/:boardDiv',
				name: 'BoardList',
				component: () => import('@/components/board/BoardList'),
			},
			{
				path: '/board/detail/:boardNo',
				name: 'BoardDetail',
				component: () => import('@/components/board/BoardDetail'),
			},
			{
				path: '/board/update/:boardNo',
				name: 'BoardUpdate',
				component: () => import('@/components/board/BoardWrite'),
			},
			{
				path: '/board/write/:boardDiv',
				name: 'BoardWrite',
				component: () => import('@/components/board/BoardWrite'),
			},
			{
				path: '/kakao/map',
				name: 'KakaoMap',
				component: () => import('@/components/board/KakaoMap'),
			},
		]
	}
];

export const router = createRouter({
	history: createWebHistory(),
	routes,
});