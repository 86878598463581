const common = {
	url: {
		_NAS_FRONT              : "https://front.xodud1202.kro.kr",
		_LOCAL_FRONT            : "http://localhost:8421",
		_PAGE_MAIN              : "/main",										// 몰메인
		_PAGE_LOGIN             : "/signin",									// GNB > 로그인
		_PAGE_LOGOUT            : "/logout",									// GNB > 로그아웃
		_PAGE_BOARD_PAGE        : "/board/list/G100_10",						// 게시판 리스트 화면
		_PAGE_BOARD_WRITE_PAGE  : "/board/write/",								// 게시판 등록 화면
		_PAGE_BOARD_UPDATE_PAGE : "/board/update/",								// 게시판 수정 화면
		_PAGE_DEVELOP_BOARD_PAGE: "/board/list/G100_12",						// 개발관련 게시판 리스트 화면
		_PAGE_BOARD_DETAIL_PAGE : "/board/detail/",								// 게시판 상세 화면
		_PAGE_PORTFOLIO_PAGE    : "/board/list/G100_13",						// 포트폴리오 게시판 리스트 화면
		_PAGE_CUSTOMER_JOIN     : "/customer/join",								// 고객 > 회원가입
		_PAGE_KAKAO_MAP_PAGE    : "/kakao/map",									// 지도검색화면
		IMG_URL                 : "//image.xodud1202.kro.kr/publist/HDD1/Media/nas/upload/common",
		KAKAO_APP_KEY           : "d566dedfdd8545731d4be50265648478",
	},
	apiUri: {
		BOARD_LIST      : "/api/board/list",
		BOARD_DETAIL    : "/api/board/detail",
		DETAIL_DIV_LIST : "/api/detail/div/list",
		FILE_UPLOAD     : "/common/file/upload",
	},
	boardDiv: {
		MAIN       : "G100_00",
		SHORT      : "G100_10",
		DEVELOP    : "G100_12",
		PORTFOLIO  : "G100_13",
	},
	detailDiv: {
		SETTING    : "G101_01",
		SPRING     : "G101_02",
		JAVASCRIPT : "G101_03",
		JQUERY     : "G101_04",
		CSS        : "G101_05",
		THYMELEAF  : "G101_06",
		AG_GRID    : "G101_07",
		MYSQL      : "G101_08",
		ORACLE     : "G101_09",
		VUE        : "G101_10",
		ETC        : "G101_99",
	},
	custGrade: {
		TEMP       : 'G110_00',
		BRONZE     : "G110_10",
		SILVER     : "G110_20",
		GOLD       : "G110_30",
		VIP        : "G110_40",
		VVIP       : "G110_50",
		ADMIN      : "G110_99",
	},
	// 아스키코드 > 특수문자로 변환
	escapeHtml(html) {
		const txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value.replaceAll("\n", "<br/>");		// 엔터 변경이 안되는것으로 보인다.
	}
}

export default {
	install(Vue) {
		Vue.config.globalProperties.$common = common;
	}
}