<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App',
	beforeMount() {
		// component 마운트 전
		this.setLoginInfo();
	},
	methods: {
		setLoginInfo() {
			if(sessionStorage.getItem("custInfo")) {
				const custInfo = JSON.parse(sessionStorage.getItem("custInfo"));
				this.$store.commit("setCustId", custInfo.custId);
				this.$store.commit("setCustNm", custInfo.custNm);
				this.$store.commit("setCustGrade", custInfo.custGrade);
				this.$store.commit("setCustGradeNm", custInfo.custGradeNm);
			}
		},
	},
}
</script>