import {createStore} from 'vuex'

const store = createStore({
	state() {
		return {
			// 로그인 정보
			custId: '',
			custNm: '',
			custGrade: '',
			custGradeNm: '',

			// 모달
			isAlertOpen: false,
			alertContent: '',
		}
	},
	mutations: {
		// state의 값을 변경해줄때 사용 (파라미터에 state>첫번째파라미터 명은 상관읎음.. 받아와야 해당 데이터에 세팅 가능. 대체 왜...?)
		setCustId(state, custId) {
			state.custId = custId;
		},
		setCustNm(state, custNm) {
			state.custNm = custNm;
		},
		setCustGrade(state, custGrade) {
			state.custGrade = custGrade;
		},
		setCustGradeNm(state, custGradeNm) {
			state.custGradeNm = custGradeNm;
		},
		logout(state) {
			state.custId = '';
			state.custNm = '';
			state.custGrade = '';
			state.custGradeNm = '';
		},
		alert(state, content) {
			state.alertContent = content;
			state.isAlertOpen = true;
		},
		closeAlert(state) {
			state.isAlertOpen = false;
		}
	},
	actions : {
		// AJAX를 사용하는곳...(일정 시간 이상이 걸리는 기능을 사용할때 사용하라고 되어있음... 어디 ?)
		getData(context) {
			console.log(context);
		}
	}
})

export default store;