import { createApp } from 'vue'
import common from "./assets/js/common"
import axios from 'axios'
import store from './store';
import App from './App.vue'
import { router } from './router/index.js'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/font-awesome.css'
import './assets/css/local.css'
import './assets/css/lty.css'

const cors = require('cors');
const app = createApp(App)		// Create Vue Instance
app.use(common)
app.use(router)
app.use(cors)
app.use(store);

app.config.globalProperties.axios=axios

app.mount('#wrapper')